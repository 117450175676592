<template>
  <div class="page">
    <div class="pageFuc">
      <div class="left">
      </div>
      <div class="right">
        <el-button type="primary" @click="dialogVisible = true">新增</el-button>
      </div>
    </div>
    <el-table :data="categoryList">
      <el-table-column label="分类" prop="name"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-link type="primary" :underline="false" @click="edit(scope.row)" style="margin-right: 10px">编辑</el-link>
          <el-link type="danger" :underline="false" @click="del(scope.row.id)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
        <el-form>
          <el-form-item label="分类名">
            <el-input v-model="cateForm.name"></el-input>
          </el-form-item>
          <el-form-item label="分类排序">
            <el-input v-model="cateForm.sort_order"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogSubmit">确 定</el-button>
    </span>
    </el-dialog>
  </div>
</template>

<script>
import {apiGetArticleCategory, apiDelCategory,apiCreateOrUpdateCategory } from "@/request/api"
export default {
  name: "articleCate",
  data() {
    return {
      categoryList:[],
      cateForm:{
        name:'',
        sort_order:''
      },
      dialogVisible:false
    }
  },
  activated() {
    this.getCategoryList()
  },
  methods:{
    getCategoryList(){
      apiGetArticleCategory().then(res => {
        if(res.code == 200){
          this.categoryList = res.data
        }
      })
    },
    del(id){
      this.$confirm('确定删除该分类?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        apiDelCategory({id:id}).then(res => {
          if(res.code == 200){
            this.getCategoryList()
          }
        })
      })
    },
    edit(obj){
      this.cateForm={
        name:obj.name,
        sort_order:obj.sort_order,
        id:obj.id
      }
      this.dialogVisible = true
    },
    handleClose(done){
      this.cateForm={
        name:'',
        sort_order:''
      }
      done()
    },
    dialogSubmit(){
      apiCreateOrUpdateCategory(this.cateForm).then(res => {
        if(res.code == 200){
          this.cateForm={
            name:'',
            sort_order:''
          }
          this.dialogVisible = false
          this.getCategoryList()
        }
      })
    }
  }
}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
}
.pageFuc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.pageView {
  display: flex;
  justify-content: flex-end;
}
</style>
